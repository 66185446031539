.blogs-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.columns-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  font-family: 'Righteous', cursive;
  margin-top: 5rem;
  margin-bottom: 2.5rem;
}

.blog-list-wrapper {
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0;
}

.num-column {
  width: 100%;
  text-align: center;
  list-style-type: none;
}

.num-link {
  width: 100%;
  text-decoration: none;
  color: black;
}

.blog-column {
  width: 100%;
  text-align: center;
  list-style-type: none;
}

.blog-link {
  text-decoration: none;
  color: black;
}

.date-column {
  width: 100%;
  text-align: center;
  list-style-type: none;
}

@media screen and (max-width: 525px) {
  .num-column {
    display: none;
  }
}
