.blog-wrapper {
  width: 95%;
  border: 1px solid #eaeaea;
}

.blog-header {
  display: flex;
  justify-content: space-between;
  margin: 1em;
}

.blog-date {
  /* margin-right: 1rem; */
  font-family: 'Righteous', cursive;
}

.blog-text-wrapper {
  text-align: center;
  margin: 1em;
  padding: 1rem;
  height: 100%;
}

.image-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.blog-image {
  width: 60%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 525px) {
  .blog-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    text-align: center;
  }
}
