.footer {
  left: 0;
  bottom: 0;
  right: 0;
  position: sticky;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.5rem;
  width: 100%;
}

.icons-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.5rem;
}
@media screen and (max-width: 700px) {
  .icons-wrapper {
    display: flex;
    justify-content: center;
  }
  .footer {
    margin: 0;
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .icons-wrapper {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 525px) {
  .footer {
    height: 90px;
  }
}
