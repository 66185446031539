@import url('https://fonts.googleapis.com/css2?family=Righteous&family=Titillium+Web:wght@200&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Titillium Web', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

li {
  list-style: none;
}

.wrapper {
  min-height: 100vh;
  transition: 0.2s all linear;
  border-radius: 5px;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn {
  border: none;
  font-size: 1.25rem;
  cursor: pointer;
  transition: 0.2s all linear;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 5px;
}

.paragraph {
  width: 100%;
  height: 100%;
  font-size: 1.25rem;
  text-align: left;
  line-height: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  transition: all 0.2s linear;
}

/* Light Mode Styles */

.bg-light {
  background-color: #fff;
}

.num-header-light {
  height: 100%;
  color: #000;
  width: 100%;
  font-size: 1.5rem;
  margin: 0;
}

.para-light {
  color: #222;
  width: 100%;
  font-size: 1.25rem;
  line-height: 1.65rem;
}

.btn-light {
  background-color: #222;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
}

.blog-title-light {
  font-family: 'Righteous', cursive;
  color: #222;
  /* width: 100%; */
  font-size: 1.25rem;
}

.blog-date-light {
  font-family: 'Righteous', cursive;
  color: #222;
  /* width: 100%; */
  font-size: 1.25rem;
}

.light-social-media-icons {
  color: #000;
  font-size: 2rem;
  text-decoration: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 1rem;
}

/* Dark Mode Styles */

.bg-dark {
  background-color: #222;
}

.num-header-dark {
  height: 100%;
  color: #fff;
  width: 100%;
  font-size: 1.5rem;
  margin: 0;
}

.para-dark {
  color: #fff;
  width: 100%;
  font-size: 1.25rem;
  line-height: 1.65rem;
}

.btn-dark {
  background-color: #fff;
  color: #222;
  font-size: 15px;
  cursor: pointer;
}

.blog-title-dark {
  font-family: 'Righteous', cursive;
  color: #fff;
  font-size: 1.25rem;
}

.blog-date-dark {
  font-family: 'Righteous', cursive;
  color: #fff;
  font-size: 1.25rem;
}

.image-caption {
  width: 60%;
  height: 100%;
  font-size: 1.15rem;
  margin-top: 1rem;
  text-align: center;
}

.dark-social-media-icons {
  color: #fff;
  font-size: 2rem;
  text-decoration: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 1rem;
}

@media screen and (max-width: 525px) {
  .fullstackog {
    display: none;
  }
  .num-header-light {
    display: none;
  }
  .num-header-dark {
    display: none;
  }
}

@media screen and (max-width: 540px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
