.nav {
  width: 50%;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  height: 55px;
  margin-top: 2.5rem;
  font-family: 'Righteous', cursive;
}

.toggleThemeButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.links {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  padding: 0rem;
}

.theme-button {
  color: #fff;
  cursor: pointer;
  list-style: none;
}

.link {
  height: 100%;
  width: 7rem;
  font-size: 1.5rem;
  text-align: center;
  text-decoration: none;
  list-style-type: none;
  color: black;
  display: flex;
  align-items: center;
}

.name {
  width: 7rem;
  text-align: center;
  font-weight: normal;
  margin-right: 1rem;
}

@media screen and (max-width: 525px) {
  .nav {
    margin: 0;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  .links {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .link {
    margin-top: 0.5em;
  }
}
