.headline-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
}

.headline {
  font-weight: bold;
  text-align: center;
}

.about {
  text-align: center;
  margin-top: 2rem;
  line-height: 1.8rem;
  width: 80%;
  font-size: 1.25rem;
}

.parent {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  margin: 1rem;
  padding: 1rem;
  align-items: center;
}

.photo-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.photo {
  width: 150px;
  max-width: 100%;
  object-fit: contain;
  border-bottom-left-radius: 25px;
  border-top-right-radius: 25px;
}

.paragraph {
  box-sizing: border-box;
  line-height: 2rem;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  .parent {
    display: flex;
    justify-content: space-around;
  }
  .photo-wrapper {
    width: 100%;
    margin: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .paragraph {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 700px) {
  .parent {
    flex-direction: column-reverse;
  }
  .about {
    text-align: center;
  }
  .headline {
    text-align: center;
  }
}
